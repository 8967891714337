<template>
  <Fallback :is-loading>
    <template #loading-state>
      <Spinner size="sm" class="m-2" />
    </template>
    <Button
      class="hover:bg-transparent active:bg-transparent"
      variant="ghost"
      :icon="active ? 'heroicons:bookmark-solid' : 'heroicons:bookmark'"
      @click="onClickFavoriteButton" />
  </Fallback>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { useAPI } from '~/composables/shared/useAPI'
import type { FavoriteButtonProps } from './types'

const api = useAPI()
const props = defineProps<FavoriteButtonProps>()
const emits = defineEmits(['applied'])
const active = ref(props.active)
const isLoading = ref(false)

const { mutate: createFavorites } = useMutation({
  mutationFn() {
    switch (props.category) {
      case 'collection':
        return api.catalog.dataset.createFavorites({ path: props.target })
      case 'project':
        return api.project.project.createFavorites({ path: props.target })
      case 'experiment':
        return api.machineLearning.experiment.createFavorites({ path: props.target })
    }
  },
  onSuccess() {
    isLoading.value = false
    active.value = true
    emits('applied')
  },
  onError() {
    isLoading.value = false
  }
})

const { mutate: destroyFavorites } = useMutation({
  mutationFn() {
    switch (props.category) {
      case 'collection':
        return api.catalog.dataset.destroyFavorites({ path: props.target })
      case 'project':
        return api.project.project.destroyFavorites({ path: props.target })
      case 'experiment':
        return api.machineLearning.experiment.destroyFavorites({ path: props.target })
    }
  },
  onSuccess: () => {
    isLoading.value = false
    active.value = false
    emits('applied')
  },
  onError() {
    isLoading.value = false
  }
})

function onClickFavoriteButton() {
  isLoading.value = true
  active.value ? destroyFavorites() : createFavorites()
}
</script>
